<template>
  <div class="hello">

    <HeaderComponent/>
    <div id="map" style="position:absolute;top:0;left:0;width:100%;height: 100%;z-index: 0">
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import HeaderComponent from './HeaderComponent.vue';
import MainView from "@/views/MainView.vue";
import data from '@/data/colegios_rutas.json';
import { useMainStore } from '@/stores/mainStore';
import { useQuizStore } from '@/stores/quizStore';
import questionData  from '@/data/questions.json';


L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'HelloWorld',
  components:{
    HeaderComponent,
    MainView,
},
setup(){
  const quizStore = useQuizStore();
  const mainStore = useMainStore();
  return {mainStore,quizStore}
},
data: function () {
    return {
      data:data,
      questionData: questionData
    }
},
  props: {
    msg: String
  },
  mounted(){
    const mainStore = this.mainStore;
    const quizStore = this.quizStore;
    const data = this.data;

    let icon_out = L.icon({
      iconUrl:'./icons/icon_out.png',
      iconSize:[48,48]
    });

    let icon_in = L.icon({
      iconUrl:'./icons/icon_in.png',
      iconSize:[48,48]
    });

    function addMarkers(id){
      for(let i=0; i<data.colegios[id].locations.length; i++){
        let locationSplit = data.colegios[id].locations[i].split(',');
        let location = {
          lat: locationSplit[0],
          long: locationSplit[1],
          name: locationSplit[2]
        }
        console.log(location.lat,location.long);
        let markerLocation = L.marker([location.lat,location.long], {icon:icon_out});
        markerLocation.addTo(map)
        markerLocation.on('click', ()=>{
          console.log(`${location.name}`);
          quiz(markerLocation)
      });
      }
    }
    
    
  

    function quiz(location){
      if(L.latLng(mainStore.currentLocation).distanceTo(location.getLatLng())<30){
        console.log('in!');
        location.setIcon(icon_in);
      }else{
        console.log('out!');
      }    
    }



    const m_mono = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    attribution: ""
    });

    const map = L.map('map', {
    center: [39.4370500,-0.4654600],
    zoom: 14,
    zoomControl: false,
    layers: [m_mono]
  });

    mainStore.currentMap = map;


    map.createPane("locationMarker");
    map.getPane("locationMarker").style.zIndex = 999;

  const marker_test = L.marker([40.457966,-3.6905772], {icon:icon_out});

  //marker_test.addTo(map);

  let location;

    if(!navigator.geolocation){
      console.log("your browser doesn't support geolocation");
    }else{
      navigator.geolocation.watchPosition(setPos,failPos, {enableHighAccuracy:true});
    }

    function failPos(){
      console.log('ERROR')
    }

    let positionSet = false;

    function setPos(position){
      if (positionSet === false){
        positionSet = true;
        location = L.circleMarker([position.coords.latitude,position.coords.longitude], {pane:"locationMarker",radius:9,fillOpacity:1,stroke:true, color:'#80d5ff',fillColor:'#33bbff',weight:3}).addTo(map);
      }else{
        getPos(position)
      }
    }

    function getPos(position){
      console.log(position);
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      let accuracy = position.coords.accuracy;

      let LatLng = {
        lat: lat,
        lng: long
      }
      console.log(`${lat} ${long} ${accuracy}`);
      location.setLatLng(LatLng);
      console.log(L.latLng(LatLng).distanceTo(marker_test.getLatLng()));
      mainStore.currentLocation = LatLng;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
