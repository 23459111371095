<template>
    <div class="pin" v-show="this.mainStore.currentView==='pinView'" style="background-image: url('./img/bg.png');">
        <h1>Introduce el código de tu equipo</h1>
        <input id="input" type="text" placeholder="0000" ref="pin_input">
        <span class="error" ref="error_message" v-show="error===true">ERROR: El código no es válido, prueba de nuevo</span>
        <button @click="checkPin(this.$refs.pin_input)">Continuar</button>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore';
import data from '@/data/colegios_rutas.json';
import gsap from 'gsap';
import L from 'leaflet'
import { useQuizStore } from '@/stores/quizStore';
import questionData  from '@/data/questions.json';
import { get, collection, doc, setDoc, getDoc, getDocs, getFirestore, updateDoc } from "@firebase/firestore";
import { initializeApp } from "firebase/app"
import {getAuth} from '@firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyBa1fFKZ2trNfBl7HAO6AA7pPDr349Jb5A",
    authDomain: "torrent-d34fc.firebaseapp.com",
    projectId: "torrent-d34fc",
    storageBucket: "torrent-d34fc.appspot.com",
    messagingSenderId: "267657519515",
    appId: "1:267657519515:web:35a3973a770ea2d74f813e"
  };
  

const firebaseapp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseapp);

const db = getFirestore();

const icon_forns_grey = L.icon({
                iconUrl:`./icons/forns_off.png`,
                iconSize:[48,48]
                });

const icon_fruteries_grey = L.icon({
                iconUrl:`./icons/fruteries_off.png`,
                iconSize:[48,48]
                });

const icon_carnisseries_grey = L.icon({
                iconUrl:`./icons/carnisseries_off.png`,
                iconSize:[48,48]
                });

const icon_altres_grey = L.icon({
                iconUrl:`./icons/fruteries_off.png`,
                iconSize:[48,48]
                });



export default{
    name:'pinView',
    setup(){
        const quizStore = useQuizStore();
        const mainStore = useMainStore();
        return {mainStore,quizStore}
    },
    data: function(){
        return{
            error: false,
            errorCount: 0,
            questionData:questionData
        }
    },
    methods:{
        questionGen: function(cat){
            let index = Math.floor(Math.random()*questionData.questions[cat].length);
            let question = questionData.questions[cat][index];
            this.quizStore.currentQuestion = question.question;
            this.quizStore.answer0 = question.a1;
            this.quizStore.answer1 = question.a2;
            this.quizStore.answer2 = question.a3;
            this.quizStore.correct = question.correct;
            this.quizStore.questionCategory = cat;
            this.quizStore.questionIndex = index;
        },

        quiz: function(location,cat){
            if(L.latLng(this.mainStore.currentLocation).distanceTo(location.getLatLng())<30){
                //console.log('in!');
                this.mainStore.isQuizShowing = true;
                this.mainStore.currentClickedLocation = location;
                this.questionGen(cat);
            }else{
                //console.log('out!');
                this.mainStore.isErrorShowing = true;
                this.mainStore.currentClickedLocation = location;
                //this.mainStore.isQuizShowing = true;//TESTING
                //this.questionGen(cat);//TESTING
            }
        },
        setOnEnter: async function(localization,code){
            const group = doc(db, "colegios", localization);
            const date = new Date();
            getDoc(group)
            .then((data)=>{
                if(data.exists()){
                    console.log(localization);
                }else{
                    setDoc(group,{
                        school: localization,
                        lastStart: date.toLocaleDateString(),
                        code: code,
                    }).then((data)=>{
                        console.log(data)
                    }).then((err)=>{
                        console.log(err)
                    })
                }
            })
        },
        checkPin: function (input){
            let locations = [];
            if(input.value in data.colegios){
                this.mainStore.currentPin = input.value;
                this.mainStore.currentView = 'experienceView';
                this.mainStore.schoolName = data.colegios[input.value].name;
                this.setOnEnter(this.mainStore.schoolName,this.mainStore.currentPin);
                this.quizStore.questions = questionData.questions;
            for(let i=0; i<data.colegios[input.value].locations.length; i++){
                let locationSplit = data.colegios[input.value].locations[i].split(',');
                    let location = {
                    lat: locationSplit[0],
                    long: locationSplit[1],
                    name: locationSplit[2],
                    category: locationSplit[3]
                }
                locations.push(location.name);
                this.mainStore.locations = locations;
                console.log(this.mainStore.locations)
                console.log(location.lat,location.long);

                let icon_out = L.icon({
                iconUrl:`./icons/${location.category}.png`,
                iconSize:[48,48]
                });

                let isClicked = false;
                let markerLocation = L.marker([location.lat,location.long], {icon:icon_out,customId:`m${i}`,isResponded:false});
                markerLocation.addTo(this.mainStore.currentMap)
                markerLocation.on('click', ()=>{
                if(markerLocation.options.isResponded){
                    this.mainStore.isAlreadyRespondedShowing = true;
                    console.log(markerLocation.options.isResponded);
                }else{
                    console.log(`${location.name} ${location.category}`);
                    this.quiz(markerLocation,location.category);
                    console.log(markerLocation.options.isResponded);
                    isClicked=true;
                }
                });
            }
            }else{
                this.error = true;
                this.errorCount+=1;
                if(this.errorCount>1){
                    const tl = gsap.timeline({paused:true});
                    tl.to(this.$refs.error_message,{x:-10,duration:.2});
                    tl.to(this.$refs.error_message,{x:10,duration:.2});
                    tl.to(this.$refs.error_message,{x:-10,duration:.2});
                    tl.to(this.$refs.error_message,{x:10,duration:.2});
                    tl.to(this.$refs.error_message,{x:0,duration:.2});
                    tl.play()
                }
            }            
        }
    }

}
</script>

<style scoped>
.pin{
    position: absolute;
    z-index: 2;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:15px;
}

h1{
    font-family: 'Bree Serif';
    font-size: 22px;
    font-weight: 300;
    color:#0300A6;
}

.error{
    color:red;
    font-family: 'Bree Serif';
    font-weight: 300;
}

input{
    text-align: center;
    height: 50px;
    font-size: 22px;
}

button{    
    background: #90C172;
    border-radius: 20px;
    color:#fff;
    font-family: 'Bree Serif';
    border: none;
    outline: none;
    width:70%;
    height: 40px;
    }
</style>