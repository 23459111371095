<template>
    <div class="quiz" v-show="mainStore.isQuizShowing === true" 
    :style="quizStore.questionCategory === 'fruteries' ? `background-image:url(${bg_frutas})`: quizStore.questionCategory === 'carnisseries' ? `background-image:url(${bg_carnisseries})` : quizStore.questionCategory === 'altres' ? `background-image:url(${bg_altres})` : quizStore.questionCategory === 'forns' ? `background-image:url(${bg_forns})` : ''">
        <div class="quiz_header">

            <!--<span @click="close">X</span>-->
        </div>
        <h1>{{this.quizStore.currentQuestion}}</h1>
        <div class="options">
            <button class="option" v-bind:class="{ 'active': this.quizStore.clickedAnswer === 'a1', '': this.quizStore.clickedAnswer != 'a1' }" id="a1" @click="setAnswer('a1')">{{this.quizStore.answer0}}</button>
            <button class="option" v-bind:class="{ 'active': this.quizStore.clickedAnswer === 'a2', '': this.quizStore.clickedAnswer != 'a1' }" id="a2" @click="setAnswer('a2')">{{this.quizStore.answer1}}</button>
            <button class="option" v-bind:class="{ 'active': this.quizStore.clickedAnswer === 'a3', '': this.quizStore.clickedAnswer != 'a1' }" id="a3" @click="setAnswer('a3')">{{this.quizStore.answer2}}</button>
        </div>
        <button v-show="this.quizStore.responded === false" @click="checkAnswer" class="responder">Responder</button>
        <button v-show="this.quizStore.responded === true" @click="continue_" class="responder">Continuar</button>
    </div>
</template>

<script>
import questionData  from '@/data/questions.json';
import { useMainStore } from '@/stores/mainStore';
import { useQuizStore } from '@/stores/quizStore';
import gsap from 'gsap';

import bg_frutas from '@/assets/bg_frutas.png';
import bg_carnisseries from '@/assets/bg_carnisseries.png';
import bg_forns from '@/assets/bg_forns.png';
import bg_altres from '@/assets/bg_altres.png';

import L from 'leaflet'

let currentElement;

const icon_forns_grey = L.icon({
                iconUrl:`./icons/forns_off.png`,
                iconSize:[48,48]
                });

const icon_fruteries_grey = L.icon({
                iconUrl:`./icons/fruteries_off.png`,
                iconSize:[48,48]
                });

const icon_carnisseries_grey = L.icon({
                iconUrl:`./icons/carnisseries_off.png`,
                iconSize:[48,48]
                });

const icon_altres_grey = L.icon({
                iconUrl:`./icons/fruteries_off.png`,
                iconSize:[48,48]
                });

export default {
    name:'QuizComponent',
    setup(){
        const quizStore = useQuizStore();
        const mainStore = useMainStore();
        return {mainStore, quizStore}
    },
    data: function(){
        return{
            bg_frutas:bg_frutas,
            bg_carnisseries:bg_carnisseries,
            bg_forns:bg_forns,
            bg_altres:bg_altres
        }
    },
    methods:{
        close: function(){
            this.mainStore.isQuizShowing = false;
        },
        setAnswer: function(ans){
            this.quizStore.clickedAnswer = ans;
            console.log(document.getElementById(ans));
        },
        setIconOff: function(loc,cat){
            if(cat === 'forns'){
                loc.setIcon(icon_forns_grey);
            }else if(cat === 'fruteries'){
                loc.setIcon(icon_fruteries_grey);
            }else if(cat === 'carnisseries'){
                loc.setIcon(icon_carnisseries_grey);
            }else if(cat === 'altres'){
                loc.setIcon(icon_altres_grey);
            }else if(cat === 'jamones_peiro'){
                loc.setIcon(icon_carnisseries_grey);
            }
        },
        checkAnswer: function(){
            currentElement = document.querySelector('.active');
            if(this.quizStore.clickedAnswer === this.quizStore.correct){
                console.log('correct');
                currentElement.classList.remove('active');
                currentElement.classList.add('green');
                this.quizStore.responded = true;
                this.mainStore.score += 1;
                document.getElementById('a1').setAttribute('disabled','disabled');
                document.getElementById('a2').setAttribute('disabled','disabled');
                document.getElementById('a3').setAttribute('disabled','disabled');
                this.quizStore.questions[this.quizStore.questionCategory][this.quizStore.questionIndex].responded = true;
                this.mainStore.currentClickedLocation.options.isResponded = true;
                this.setIconOff(this.mainStore.currentClickedLocation,this.quizStore.questionCategory);
                
            }else{
                currentElement.classList.add('red');
                console.log('incorrect');
                currentElement.classList.remove('active');
                currentElement.classList.add('red');
                document.getElementById(this.quizStore.correct).classList.add('green');
                this.quizStore.responded = true;
                document.getElementById('a1').setAttribute('disabled','disabled');
                document.getElementById('a2').setAttribute('disabled','disabled');
                document.getElementById('a3').setAttribute('disabled','disabled');
                this.mainStore.currentClickedLocation.options.isResponded = true;
                this.setIconOff(this.mainStore.currentClickedLocation,this.quizStore.questionCategory);
            }
        },
        continue_: function(){
            this.quizStore.responded = false;
            this.mainStore.isQuizShowing = false;
            this.quizStore.clickedAnswer = '';
            this.resetQuiz();
            this.quizStore.respondedQuestions += 1;
            document.getElementById('a1').removeAttribute('disabled','disabled');
            document.getElementById('a2').removeAttribute('disabled','disabled');
            document.getElementById('a3').removeAttribute('disabled','disabled');
        },
        resetQuiz: function(){
            document.querySelector('.option').classList.remove('green');
            document.querySelector('.option').classList.remove('red');
            document.getElementById(this.quizStore.correct).classList.remove('green');
        }
    }
}
</script>

<style scoped>
.quiz{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #FFE7D9;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    gap:15px;
    background-repeat: no-repeat;
    background-position: center center;

}

span{
    font-family: 'Bree Serif';
    color:#C80000;
    background-color: rgba(255,255,255,.6);
    padding: 5px;
    border-radius:5px ;
}

.quiz>h1{
    font-family: 'Bree Serif';
    font-weight: 300;
    color:#0300A6;
    font-size: 22px;
    text-align: center;
}

.quiz_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px;
}

.quiz_header>span{
    align-self: center;
}

.quiz_header>h1{
    font-family: 'Bree Serif';
    font-weight: 300;
    color:#0300A6;
    font-size: 22px;
}

.options{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px
}

.options>button{
    border: 4px dashed #0300A6;
    background-color: transparent;
    border-radius: 20px;
    padding: 15px;
    width: 70%;
    font-family: 'Bree Serif';
    font-size: 18px;
    color: #333;
}

.responder{    
    background: #90C172;
    border-radius: 20px;
    color:#fff;
    font-family: 'Bree Serif';
    border: none;
    outline: none;
    width:70%;
    height: 40px;
    align-self: center;
}

.active{
    background-color: #D9D9D9!important;
}

.green{
    background-color: #45A98D!important;
}

.red{
    background-color: #C11E1E!important;
}

</style>