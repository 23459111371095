<template>
    <div class="trophy_screen" v-show="this.quizStore.respondedQuestions === 5">
        <h1 v-show="this.mainStore.score === 0">Gracias por participar!</h1>
        <h1 v-show="this.mainStore.score === 1">Gracias por participar!</h1>
        <h1 v-show="this.mainStore.score === 2">Gracias por participar!</h1>
        <h1 v-show="this.mainStore.score === 3">Bien!</h1>
        <h1 v-show="this.mainStore.score === 4">Muy bien! casi aciertas todas! </h1>
        <h1 v-show="this.mainStore.score === 5">Enhorabuena! acertaste todas!</h1>

        <h1 style="color:#90C172">Aquí está tu premio en realidad aumentada!</h1>
        <model-viewer 
        id="model_viewer"
        alt="" 
        src="/models/trofeo.glb" 
        ios-src="/models/trofeo.usdz"
        ar 
        environment-image="" 
        poster="" 
        shadow-intensity="1" 
        camera-controls 
        touch-action="pan-y"
        autoplay
        ></model-viewer>

        <button @click="enableAR">Ver en AR</button>
    </div>
</template>

<script>
import '@google/model-viewer';

import { useQuizStore } from '@/stores/quizStore'
import { useMainStore } from '@/stores/mainStore';
export default{
    name:'TrophyComponent',
    setup(){
        const quizStore = useQuizStore();
        const mainStore = useMainStore();

        return {quizStore,mainStore}
    },
    methods:{
        enableAR: function(){
            const modelviewer = document.getElementById('model_viewer');
            modelviewer.activateAR();
        }
    }
}
</script>

<style scoped>
.trophy_screen{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    right:0;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJDSURBVHgB7ZjdasMwDIUTNe//otvVdt1kqOSUz2cOhVHdDBlCUv98siVLdrXuXx/HcpZ935eIWNZ1fX7nO39nOY7jUZdv9lEb27NkH31X8UON+UOD1Ci4HvXVZNQn6zROgti3kr9JO6qkVgSlUL71nc/tdhs046WKH6+Est21pCItzUo1P2haF0LzShhB1BgZV1ungh8SImfRAO7Fx0rRTg16HzkaOZX80J6kk1CIOt/v919RgpGBRSwJLeXTgTxSqB6rHaKDOyIdVZOt5q/H9+fhDkJnEigHpZZm2piZn6xKfuSg52pOYTK5zOcHz+BEE4HUdjU/aEKuNt+CcwKsc3CWjNc+6Up+cI8xalBLdC46I4UneKa1an54NKBgCmS911FDHvqq+UG4hy+HPgacpvd96icutVfJ3wjzg4Lmnp2wbJ8dVGqr5G9+lGdROONVgN80pSbG2O4Tq+Q/r9M+gG+/00gL7qBuXgqs4sfVfqRJXSt0JIcO8FPLlfxgBYGzu4s/s3uLBHPClfzw1bFRq9QgOqRrh46omD37o/JuftDLGX8JnsVl3wrc11fXhAr+RjiLH0T6ZpFZPeSxXzU/6GgeBa60Ibjv2dkkq/mbTOHhy83qzsa6V22V/CGtQvO4Rvhnw/ey9roLvDpx38nvvJD6dV7oj/zOC3kfHjp0yM4LXfE9PjNSqB6rHaKDO+IsfHZeaO+8UOeFSvmdF6L21Jfvan7nhTQxxnafWCW/80IOHeCdF+q8UOeFls4Lrf88L/QDAi6dLWkHBz4AAAAASUVORK5CYII=');
    z-index: 999;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

h1{
    font-family: 'Bree Serif';
    font-weight: 300;
    color:#0300A6;
    font-size: 22px;
    text-align: center;
}

model-viewer{
    height:60%;
}

.trophy_screen>button{
    width: 70%;
    height:48px;
    background-color: #90C172;
    border-radius:12px;
    outline:none;
    border:none;
    font-family: 'Bree Serif';
    font-size:18px;
    color:#fff;
}

</style>