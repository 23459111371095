<template>
    <div class="cover" v-show="mainStore.isErrorShowing === true">
    <div class="modal_error">
        <div class="error_message">
            Acércate más a esta localización.
        </div>
        <button @click="disappear">OK</button>
    </div>
</div>
</template>

<script>
    import { useMainStore } from '@/stores/mainStore';
    export default{
        name: 'ModalError',
        setup(){
            const mainStore = useMainStore();
            return {mainStore}
        },
        methods:{
            disappear: function(){
                this.mainStore.isErrorShowing = false;
            }
        }
    }
</script>

<style scoped>
    .cover{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 998;
    }
    .modal_error{
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        width: 50%;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-color: rgba(255,255,255,.8);
        z-index: 999;
        padding: 15px;
        gap:15px;
        border-radius: 15px;
    }

    .error_message{
        text-align: center;
        font-family: 'Bree Serif';
    }

    button{
    background: #90C172;
    border-radius: 20px;
    color:#fff;
    font-family: 'Bree Serif';
    border: none;
    outline: none;
    width:70%;
    height: 40px;
}
</style>