import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
import { initializeApp } from "firebase/app"
import {getFirestore} from 'firebase/firestore'


const pinia = createPinia()
const app = createApp(App);
app.use(pinia);
app.mount('#app');
