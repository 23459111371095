import { defineStore } from "pinia";

export const useMainStore = defineStore('main',{
    state: () => ({
        currentView: 'mainView',
        currentPin: '',
        isLocationSetted: false,
        currentLocation: null,
        schoolName: '',
        score: 0,
        currentMap: null,
        isErrorShowing: false,
        isQuizShowing: false,
        isAlreadyRespondedShowing: false,
        locations: [],
        respondedLocations: [],
        currentClickedLocation: null
    })}
)

