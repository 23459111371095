<template>
  <MainView/>
  <PinView/>
  <QuizComponent/>
  <ModalComponentError/>
  <ModalComponentLocation/>
  <TrophyComponent/>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import MainView from "@/views/MainView.vue";
import PinView from './views/PinView.vue';
import ModalComponentError from './components/ModalComponentError.vue';
import QuizComponent from './components/QuizComponent.vue';
import TrophyComponent from './components/TrophyComponent.vue';
import ModalComponentLocation from './components/ModalComponentLocation.vue';


export default {
  name: 'App',
  components: {
    HelloWorld,
    MainView,
    PinView,
    ModalComponentError,
    QuizComponent,
    TrophyComponent,
    ModalComponentLocation
}
}
</script>

<style>
*{
  margin:0;padding: 0;box-sizing: border-box;
}
.leaflet-control-attribution{
  display: none;
}
model-viewer>.ar-button{display: none;}
</style>
