<template>
    <div class="recommended_container" v-if="showAd===true">
    <div class="recommended">
        <img src="/img/warning.png" alt="">
        <p>Recomanem l'ús de Google Chrome en Android o Safari en iOS per a una millor experiència</p>
        <button @click="copyToClipboard">Copiar link</button>
        <button @click="showAd=false">Següent</button>
    </div>
    </div>

    <div class="alert_container" v-if="showAlert===true">
    <div class="alert">
        <img src="/img/warning.png" alt="">
        <p>No tanqueu aquesta pestanya en el nevegador per no perdre el progrés realitzat</p>
        <button @click="showAlert=false">Tancar</button>
    </div>
    </div>

    <div class="main" style="background-image: url('./img/bg.png');" v-show="this.mainStore.currentView === 'mainView'">
        <h2>Día del comerç local</h2>
        <h1>Gymkana Virtual</h1>
        <img src="/img/main_screen_img.svg" alt="">
        <button @click="setContinue">Comenzar</button>
    </div>
</template>

<script>
    import { useMainStore } from '@/stores/mainStore';

    export default{
        name:'MainView',
        setup(){
            const mainStore = useMainStore();
            return {mainStore};
        },
        methods:{
            setContinue: function(){
                this.mainStore.currentView = 'pinView'
            },
            copyToClipboard: function(){
            navigator.clipboard.writeText('https://experiencia-colegios.ideat.org');
            alert('Link copiat!');
            }
        },
        data: function(){
            return{
                showAd: true,
                showAlert: true
            }
        }
    }
</script>

<style scoped>
.recommended{
    background-color: rgba(255,255,255,0.9);
    border-radius: 12px;
    width: 256px;
    padding:20px;
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: center;
    font-family: 'Bree Serif';
    text-align: center;
}
.recommended_container{
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: rgba(0,0,0,.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.alert{
    background-color: rgba(255,255,255,0.9);
    border-radius: 12px;
    width: 256px;
    padding:20px;
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: center;
    font-family: 'Bree Serif';
    text-align: center;
}
.alert_container{
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: rgba(0,0,0,.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 998;
}
.main{
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

h2{
    font-family: 'Bree Serif';
    font-weight: 300;
    color:#0300A6;
}

h1{
    font-family: 'Offside';
    font-weight: 300;
    color:#90C172;
}

button{
    background: #90C172;
    border-radius: 20px;
    color:#fff;
    font-family: 'Bree Serif';
    border: none;
    outline: none;
    width:70%;
    height: 40px;
}
</style>