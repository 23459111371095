import { defineStore } from "pinia";

export const useQuizStore = defineStore('quiz',{
    state: () => ({
        questions:null,
        currentQuestion: '',
        answer0: '',
        answer1: '',
        answer2: '',
        correct: '',
        clickedAnswer: '',
        questionCategory: '',
        questionIndex: '',
        responded: false,
        respondedQuestions: 0
    })}
)

